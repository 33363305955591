import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Navbar } from "react-bootstrap";
import { useHistory, useRedirector } from "helpers/navigation";
import { Link } from "react-router-dom";
import { Button, Dropdown } from "semantic-ui-react";
import HandyLogo from "assets/HandyLogo.svg";
import { useMediaQuery } from "react-responsive";
import SearchBar from "components/UniSearch/SearchBar";
import NotificationBubble from "components/NotificationBubble";
import { MenuEntries } from "components/ProfileSidebar";
import { ReactComponent as Chevron } from "assets/ChevronRight.svg";
import { loadNotifications, getUnreadNotis } from "redux/user";
import { buttonOutlineStyle } from "helpers/theme";

import { ReactComponent as Logout } from "assets/icons8-abmelden-abgerundet-100.svg";
import { ReactComponent as Suche } from "assets/icons8-suche-100-2.svg";
import { ReactComponent as Tutorprofile } from "assets/icons8-lebenslauf-100.svg";
import { ReactComponent as Menu } from "assets/icons8-menü-100-2.svg";
import { ReactComponent as Logo } from "assets/Logo.svg";

const headerLinkStyleMobile = {
  paddingTop: 8,
  color: "#31313f",
  cursor: "pointer",
};
const headerLinkStyleTablet = {
  marginLeft: 30,
  ...headerLinkStyleMobile,
};
const headerLinkStyle = {
  marginLeft: 50,
  ...headerLinkStyleMobile,
};

const TutorLink = () => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1024px)" });
  return (
    <Link to="/tutorwerden">
      <Button
        className="buttonoutline"
        style={{
          ...buttonOutlineStyle,
          width: "200px",
          height: "44px",
          margin: isDesktop ? "0 0 0 50px" : "0 0 0 30px",
          borderColor: "#387bf5",
          borderRadius: "10px",
          color: "#387bf5",
          padding: "5px 0px ",
          fontWeight: "400",
          backgroundColor: "white",
        }}
      >
        Tutor werden
        <Chevron
          style={{
            width: "12px",
            height: 12,
            marginLeft: "4px",
            marginTop: "-3px",
          }}
        />
      </Button>
    </Link>
  );
};

const Header = ({ user, noSearch, becomingTutor, loadNotifications }) => {
  const isMedium = useMediaQuery({ query: "(min-width: 768px)" });
  const isTutorWide = useMediaQuery({ query: "(min-width: 600px)" });
  const isSearchWide = useMediaQuery({ query: "(min-width: 1000px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 1024px)" });
  const isSearchWideWithTutorButton = useMediaQuery({
    query: "(min-width: 1215px)",
  });
  const isSearchWideLoggedOut = useMediaQuery({
    query: "(min-width: 1360px)",
  });
  const loggedIn = !!user.uuid;
  const isTutor = user.isTutor;
  const history = useHistory();

  const notiCount = getUnreadNotis(user.notifications).length;
  const redirectQuery = useRedirector();

  useEffect(() => {
    loadNotifications();
  }, [user.auth, loadNotifications]);

  return (
    <Navbar
      fixed="top"
      style={{
        zIndex: "1000",
        height: 90,
        backgroundColor: "white",
        padding: isMedium ? 30 : 10,
        justifyContent: "center",
        borderBottom: "1px solid",
        borderColor: "#f4f6fa",
      }}
    >
      <div
        className="link_header"
        style={{
          maxWidth: 1380,
          width: "100%",
          alignItems: "center",
          display: "flex",
        }}
      >
        <div>
          {isMedium ? (
            <>
              {loggedIn ? (
                <>
                  <Link
                    to="/"
                    style={{
                      marginRight: isTutor
                        ? isSearchWide
                          ? 50
                          : 0
                        : isSearchWideWithTutorButton
                        ? 50
                        : 0,
                    }}
                  >
                    <Logo style={{ height: 20, width: "auto" }} />
                  </Link>
                  {!noSearch && !isTutor && !isSearchWideWithTutorButton && (
                    <Link
                      to="/"
                      className="Such_Icon_Header_Link"
                      style={{
                        ...(isDesktop
                          ? headerLinkStyle
                          : headerLinkStyleTablet),
                      }}
                    >
                      <Suche
                        className="Such_Icon_Header"
                        style={{ width: 22, height: "auto", fill: "#595963" }}
                      />
                    </Link>
                  )}
                  {!noSearch && isTutor && !isSearchWide && (
                    <Link
                      to="/"
                      className="Such_Icon_Header_Link"
                      style={{
                        ...(isDesktop
                          ? headerLinkStyle
                          : headerLinkStyleTablet),
                      }}
                    >
                      <Suche
                        className="Such_Icon_Header"
                        style={{ width: 22, height: "auto", fill: "#595963" }}
                      />
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link
                    to="/"
                    style={{ marginRight: isSearchWideLoggedOut ? 50 : 0 }}
                  >
                    <Logo style={{ height: 20, width: "auto" }} />
                  </Link>
                  {!noSearch && !isSearchWideLoggedOut && (
                    <Link
                      to="/"
                      className="Such_Icon_Header_Link"
                      style={{
                        ...(isDesktop
                          ? headerLinkStyle
                          : headerLinkStyleTablet),
                      }}
                    >
                      <Suche
                        className="Such_Icon_Header"
                        style={{ width: 22, height: "auto", fill: "#595963" }}
                      />
                    </Link>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <Link to="/" style={{ marginRight: 0 }}>
                <img
                  src={HandyLogo}
                  alt={"Logo von Unitutors"}
                  style={{ height: 28 }}
                />
              </Link>
              {!noSearch && (
                <Link
                  to="/"
                  className="Such_Icon_Header_Link"
                  style={headerLinkStyleTablet}
                >
                  <Suche
                    className="Such_Icon_Header"
                    style={{ width: 22, height: "auto", fill: "#595963" }}
                  />
                </Link>
              )}
            </>
          )}
        </div>
        {!noSearch &&
          (isTutor || isSearchWideWithTutorButton) &&
          (loggedIn || isSearchWideLoggedOut) &&
          isSearchWide && (
            <>
              <SearchBar inHeader />
            </>
          )}
        {isTutorWide && !loggedIn && (
          <>
            <div
              style={{
                marginLeft: "auto",
                paddingLeft: isSearchWideLoggedOut ? 50 : 0,
              }}
            >
              <Link
                to={"/login" + redirectQuery.str}
                style={{
                  ...(isDesktop ? headerLinkStyle : headerLinkStyleTablet),
                }}
              >
                Anmelden
              </Link>
              <Link
                style={{
                  ...(isDesktop ? headerLinkStyle : headerLinkStyleTablet),
                }}
                to={"/signup" + redirectQuery.str}
              >
                Registrieren
              </Link>
              <TutorLink />
            </div>
          </>
        )}
        {loggedIn && (
          <>
            {!isTutor && !becomingTutor && isTutorWide && (
              <>
                <div
                  style={{
                    marginLeft: "auto",
                    paddingLeft: isSearchWideWithTutorButton ? 50 : 0,
                  }}
                >
                  <TutorLink />
                </div>
              </>
            )}
            <div
              style={{
                marginLeft: !isTutor
                  ? !becomingTutor
                    ? isTutorWide
                      ? ""
                      : "auto"
                    : "auto"
                  : "auto",
              }}
            >
              <Dropdown
                className="userMenu"
                icon={null}
                direction={"left"}
                trigger={
                  <div
                    style={{
                      border: "3px solid #dfeafc",
                      borderRadius: 1000,
                      padding: 3,
                      width: 52,
                      height: 52,
                      marginLeft: isTutor && isSearchWide ? 100 : 50,
                    }}
                  >
                    <NotificationBubble count={notiCount} />
                    {user.profilePicture && (
                      <img
                        alt={"Profilbild"}
                        src={user.profilePicture}
                        style={{
                          borderRadius: 1000,
                          width: 40,
                          height: 40,
                        }}
                      />
                    )}
                  </div>
                }
              >
                <Dropdown.Menu>
                  {!isTutor && !isTutorWide && (
                    <>
                      <Dropdown.Item
                        icon={
                          <Tutorprofile
                            style={{ marginRight: 10 }}
                            height={20}
                            width={20}
                          />
                        }
                        text="Tutor werden"
                        onClick={() => history.push("tutorwerden")}
                      />
                      <Dropdown.Divider />
                    </>
                  )}

                  <MenuEntries />
                  <Dropdown.Divider />
                  <Dropdown.Item
                    icon={
                      <Logout
                        style={{ marginRight: 10 }}
                        height={20}
                        width={20}
                      />
                    }
                    text="Ausloggen"
                    onClick={() => history.push("login", [], { force: true })}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </>
        )}
        {!isTutorWide && !loggedIn && (
          <>
            <div style={{ marginLeft: "auto" }}>
              <Dropdown
                id="dropdown_mobile_menu_logged_out"
                icon={
                  <Menu
                    style={{ width: 22, height: "auto", fill: "#818898" }}
                  />
                }
                direction="left"
                floating
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    text="Tutor werden"
                    onClick={() => history.push("tutorwerden")}
                  />
                  <Dropdown.Divider />
                  <Dropdown.Item
                    text="Anmelden"
                    onClick={() => history.push("login")}
                  />
                  <Dropdown.Item
                    text="Registrieren"
                    onClick={() => history.push("signup")}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </>
        )}
      </div>
    </Navbar>
  );
};

const SearchHeader = connect(({ user }) => ({ user }), { loadNotifications })(
  Header
);
const NoSearchHeader = (ps) => <SearchHeader {...ps} noSearch />;

export { NoSearchHeader, SearchHeader };
